import MenuConfigType from '@app/types/MenuConfigType';
import MenuItem from '@app/types/MenuItemType';

const mapMenuConfig = (menuConfig: MenuConfigType[], menuItems: MenuItem[]): MenuConfigType[] => {
  const mappedConfigs = menuConfig.map((item) => {
    const foundItem = menuItems.find((val) => val.key === item.key);
    if (foundItem) {
      return {
        ...item,
        privileged: true,
        title: foundItem.name,
        order: foundItem.order,
        children: item.children ? mapMenuConfig(item.children, foundItem.subMenus) : [],
      };
    } else {
      return {
        ...item,
        privileged: false,
      };
    }
  });
  return mappedConfigs.sort((a, b) => {
    if (a.order && b.order) {
      return a.order - b.order;
    } else {
      return 0;
    }
  });
};

export default mapMenuConfig;
