const constants = {
  tokenKey: 'CP_AUTH_TOKEN',
  tenatKey: 'CP_TENAT',
  redirectUri: window.location.origin + '/login/callback',
  logoutUrl: window.location.origin + '/logout',
  scopes: ['openid', 'profile', 'tenancy'],
  siteSelectionKey: 'siteSelection',
  sideMenuCollapse: 'sideMenuCollapes',
  postSigninRedirect: '/submittedFiles',
  baseUrlPlaceholder: 'https://yjdevweb.conarc.net',
};

export default constants;
export const config = './config.json';
