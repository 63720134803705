import { RootState } from '@app/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileRecord } from '../forms/UploaderSubmit/types';

export interface FileDetailsState {
  succeededFiles: FileRecord[];
  pendingSave: boolean;
}

export const initialState: FileDetailsState = {
  succeededFiles: [],
  pendingSave: false,
};

export const appSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {
    setSucceededFiles: (state: FileDetailsState, action: PayloadAction<FileRecord[]>) => {
      state.succeededFiles = action.payload;
    },
    setPendingSave: (state: FileDetailsState, action: PayloadAction<boolean>) => {
      state.pendingSave = action.payload;
    },
  },
});

export const { setPendingSave, setSucceededFiles } = appSlice.actions;
export const selectUploaderState = (state: RootState) => state.uploader;

export default appSlice.reducer;
