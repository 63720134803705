import { useLazyGetSitesInfiniteRecordsQuery } from '@app/api/sitesApiSlice';
import { useLazyGetPublishedFileCountQuery } from '@app/api/fileManagementApiSlice';
import { useLazyGetNavigationMenuQuery } from '@app/api/userApiSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/useAppSelector';
import { selectAppInitializing, setApiError, setEndpoints, setMenu, setPublishedFileCount, setSelectedSite, setTenant, setUser , setTenantContext} from '@app/appSlice';
import { errorNotification } from '@app/utils/antNotifications';
import constants from '@app/utils/Constants';
import { OperationalRoleTypes } from '@iris/discovery.fe.client';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { DiscoveryClient } from '../../index';
import TenantSelectionPage from '@pages/TenentSelectionPage';

const AppInitialization = () => {
  const { tenant, endPoints, menuItems, isInitializing, selectedSite } = useAppSelector(selectAppInitializing);

  const [triggerGetSitesInfinityRecords] = useLazyGetSitesInfiniteRecordsQuery();
  const [triggerGetPublishedFileCount] = useLazyGetPublishedFileCountQuery();

  const dispatch = useAppDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const [triggerGetNavigationMenu] = useLazyGetNavigationMenuQuery();
  let interval: NodeJS.Timeout;

  useEffect(() => {
    if (selectedSite) {
      triggerGetPublishedFileCount({ siteId: selectedSite }).then(({ data }) => {
        dispatch(setPublishedFileCount(Number(data?.totalRecordCount)));
      });
      interval = setInterval(
        () =>
          triggerGetPublishedFileCount({ siteId: selectedSite }).then(({ data }) => {
            dispatch(setPublishedFileCount(Number(data?.totalRecordCount)));
          }),
        180000
      );
    }
    return () => interval && clearInterval(interval);
  }, [selectedSite]);

  const fetchEndpointsAndUser = async (tenant: string | undefined) => {
    const data = await DiscoveryClient.getBaseURL(constants.tokenKey, {
      TenantCode: tenant,
      RoleType: [OperationalRoleTypes.DocumentManagementRole],
    });

    const user = await oktaAuth.getUser();
    if (data && user) {
      dispatch(setEndpoints(data));
      dispatch(setUser(user));
    }
  };

  const fetchTenantContext = async (tenant: string) => {
    const data = await DiscoveryClient.getTenantContexts(constants.tokenKey, tenant);
    if (data.length > 0) {
      dispatch(setTenantContext(data[0]));
    }
  };

  const fetchMenusAndSites = async () => {
    const { data: menus } = await triggerGetNavigationMenu();
    const { data: sites } = await triggerGetSitesInfinityRecords({ limit: 10, offset: 0 });

    if (menus && sites) {
      dispatch(setMenu(menus));
      dispatch(setSelectedSite(sites.records[0].id));
    }
  };

  useEffect(() => {
    let tenantIds = (authState?.accessToken?.claims.tid as Array<string>) ?? [];

    if (tenantIds.length === 1 && !tenant) dispatch(setTenant(tenantIds[0]));
    if (authState?.isAuthenticated && tenant) {
      if (!tenantIds.find((e) => e == tenant)) dispatch(setTenant(null));

      if (endPoints === null) {
        fetchEndpointsAndUser(tenant).catch((e) => {
          dispatch(setApiError(e.code));
          errorNotification([''], 'Discovery Service Error');
        });
      } else if (menuItems.length === 0) {
        fetchMenusAndSites().catch((e) => {
          dispatch(setApiError(e.code));
        });
      }
    }
    if(endPoints && tenant){
      fetchTenantContext(tenant).catch((e) => {
        dispatch(setApiError(e.code));
      });
    }
  }, [authState?.isAuthenticated, tenant, endPoints]);

  return (
    <>
      {!tenant && authState?.isAuthenticated && <TenantSelectionPage />}
      {isInitializing ?? <Skeleton />}
    </>
  );
};
export default AppInitialization;
