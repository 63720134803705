import axios from 'axios';

import HTTPResponse from '@app/utils/http/interfaces/HttpResponse';
import config from '@app/utils/config';
import { CancelToken } from 'axios';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import OktaAuthClient from '@app/utils/oktaAuthClient/OktaAuthClient';
import changeUrl from '@app/api/changeUrl';
import { httpVerbs } from '@app/utils/http';

interface IUpload {
  formData: FormData;
  onUploadProgress?: (progressEvent: any) => void;
  cancelToken?: CancelToken;
}

/**
 * @description The reason of using this axios request is RTK query doesn't support onProgress events
 * @param upload
 */
export const upload = (upload: IUpload): Promise<HTTPResponse<any>> => {
  const { formData, onUploadProgress, cancelToken } = upload;
  const [baseUrl, path] = changeUrl(config.api[OperationalServiceTypes.FileStorageService].uploadFiles);

  return axios({
    method: httpVerbs.POST,
    url: `${baseUrl}${path}`,
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${OktaAuthClient()?.getAccessToken()}`,
    },
    data: formData,
    onUploadProgress: onUploadProgress,
    cancelToken: cancelToken,
  });
};
