import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiSlice as fileApiSlice } from '../api/fileApiSlice';
import { apiSlice as fileManagementApiSlice } from '../api/fileManagementApiSlice';
import { apiSlice as userApiSlice } from '../api/userApiSlice';
import { apiSlice as infiniteRecordsApiSlice } from '../api/sitesApiSlice';
import { apiSlice as firmApiSlice } from '../api/firmApiSlice';
import appReducer from '../appSlice';
import uploaderReducer from '@app/components/FileUploader/uploaderSlice';
import { injectStore } from '@app/api/changeUrl';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [fileApiSlice.reducerPath, fileManagementApiSlice.reducerPath, userApiSlice.reducerPath, infiniteRecordsApiSlice.reducerPath, 'app', 'uploader'],
};

const reducers = combineReducers({
  [fileApiSlice.reducerPath]: fileApiSlice.reducer,
  [fileManagementApiSlice.reducerPath]: fileManagementApiSlice.reducer,
  [userApiSlice.reducerPath]: userApiSlice.reducer,
  [infiniteRecordsApiSlice.reducerPath]: infiniteRecordsApiSlice.reducer,
  [firmApiSlice.reducerPath]: firmApiSlice.reducer,
  app: appReducer,
  uploader: uploaderReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(fileApiSlice.middleware)
      .concat(fileManagementApiSlice.middleware)
      .concat(userApiSlice.middleware)
      .concat(firmApiSlice.middleware)
      .concat(infiniteRecordsApiSlice.middleware),
});
export const persistStor = persistStore(store);

/**
 *  Expose an `injectStore` function from the interceptor file instead of accessing avoid importing the store directly !
 *  History : Uncaught ReferenceError: Cannot access 'baseQuery' before initialization
 *  This error orchard  when store is directly access in codebase (specially in non component files )
 *  (Note : impact after remove http usages  due to change of dependency injections [updatePublishedFilesCount method removal form app/index])
 */
injectStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducers>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
