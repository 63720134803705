import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Skeleton } from 'antd';
import { Outlet } from 'react-router-dom';
import { selectAppInitializing } from '@app/appSlice';
import { useAppSelector } from '@app/hooks/useAppSelector';

export function PrivateRoute() {
  const { tenant, endPoints } = useAppSelector(selectAppInitializing);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated || !tenant) {
    return <></>;
  }

  if (!endPoints) {
    return <Skeleton />;
  }
  
  return <Outlet />;
}
