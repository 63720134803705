import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './interceptorsSlice';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import config from '@app/utils/config';
import { GetSitesInfiniteRecordsRequest, GetSitesInfiniteRecordsResponse } from '@app/types/sitesApiSliceTypes';

export const apiSlice = createApi({
  reducerPath: '/sites',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    // ignored `queryParameters` and `filters` in args, till the actual API collection is released
    getSitesInfiniteRecords: builder.query<GetSitesInfiniteRecordsResponse, GetSitesInfiniteRecordsRequest>({
      query: (args) => {
        return `${config.api[OperationalServiceTypes.PortalService].siteSearch}?limit=${args.limit}&offset=${args.offset}${args.search ? `&search=${args.search}` : ''}`;
      },
    }),
  }),
});

export const { useLazyGetSitesInfiniteRecordsQuery } = apiSlice;
