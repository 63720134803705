import {
  DownloadFileURIRequest,
  DownloadFileURIResponse,
  DownloadZipFileURIRequest,
  DownloadZipFileURIResponse,
  GetPublishedFileCountRequest,
  GetPublishedFileCountResponse,
  GetPublishedFileRequest,
  GetPublishedFileResponse,
} from '@app/types/FileApiSliceTypes';
import { getParameterizedUrl } from '@app/utils';
import config from '@app/utils/config';
import formatDownloadFileName from '@app/utils/files/formatDownloadFileName';
import httpVerbs from '@app/utils/http/httpVerbs';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import { createApi } from '@reduxjs/toolkit/query/react';
import download from 'downloadjs';
import { baseQueryWithReAuth } from './interceptorsSlice';
import { upload } from '@app/api/uploadService';

export const apiSlice = createApi({
  reducerPath: '/fileManagement',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['publishedFiles', 'submittedFiles'],
  endpoints: (builder) => ({
    getPublishedFile: builder.query<GetPublishedFileResponse, GetPublishedFileRequest>({
      query: ({ siteId, options }) => ({
        url: `${config.api[OperationalServiceTypes.FileManagementService].getPublishedFiles}`,
        params: { siteId, ...options },
      }),
      providesTags: ['publishedFiles'],
    }),
    getPublishedFileCount: builder.query<GetPublishedFileCountResponse, GetPublishedFileCountRequest>({
      query: ({ siteId }) => ({
        url: `${config.api[OperationalServiceTypes.FileManagementService].getPublishedFilesCount}`,
        params: { siteId },
      }),
      providesTags: ['publishedFiles'],
    }),
    downloadFileURI: builder.query<DownloadFileURIResponse, DownloadFileURIRequest>({
      query: ({ fileId }) => ({
        url: getParameterizedUrl(`${config.api[OperationalServiceTypes.FileManagementService].downloadFile}`, fileId),
      }),
    }),
    downloadZipFileURI: builder.mutation<DownloadZipFileURIResponse, DownloadZipFileURIRequest>({
      query: ({ fileIds }) => ({
        url: `${config.api[OperationalServiceTypes.FileManagementService].downloadZipFile}`,
        method: httpVerbs.POST,
        body: { fileIds },
      }),
    }),
  }),
});

export const { useGetPublishedFileQuery, useLazyGetPublishedFileCountQuery, useLazyDownloadFileURIQuery, useDownloadZipFileURIMutation } = apiSlice;
