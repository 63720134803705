import React from 'react';
import { Layout, Skeleton } from 'antd';
import Header from '../../components/Header';
// import Breadcrumbs from '../../components/Breadcrumbs';
import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';
import SideMenuContainer from '../../components/SideMenu/SideMenuContainer';
import styles from './index.module.less';
import { useAppSelector } from '@app/hooks/useAppSelector';
import { selectAppInitializing } from '@app/appSlice';
import { errorNotification } from '@app/utils/antNotifications';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '@app/components/Breadcrumbs';

const MasterLayout = (props: any) => {
  const { apiError, isInitializing } = useAppSelector(selectAppInitializing);

  if (apiError) {
    errorNotification([apiError], 'App Initialization Error');
  }

  return (
    <Layout className={'yj_cp_main_layout'} style={{ height: '100vh' }}>
      <Header {...props} />
      <Layout>
        <SideMenuContainer />
        <Layout className={styles.yj_cp_masterlayout_wrapper}>
          <Breadcrumbs />
          {isInitializing ? <Skeleton /> : <Outlet />}
        </Layout>
      </Layout>
    </Layout>
  );
};

export { PageTitle, PageContent };
export default MasterLayout;
