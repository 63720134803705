import { useAppSelector } from '@app/hooks/useAppSelector';
import menuConfig from '@app/menus/menuConfig';
import { selectAppState } from '@app/appSlice';
import React, { useEffect } from 'react';
import SideMenu from '.';
import useLocalStorage from '../../hooks/useLocalStorage';
import mapMenuConfig from './utils/mapMenuConfig';

const treeKey = 'CPMenuTree';

const SideMenuContainer = () => {
  const { menuItems } = useAppSelector(selectAppState);

  const [menu, setMenu] = useLocalStorage(treeKey, []);

  useEffect(() => {
    if (menuItems && menuItems.length > 0) {
      setMenu(menuItems);
    }
  }, [menuItems, setMenu]);

  return <SideMenu menu={mapMenuConfig(menuConfig, menu)} />;
};

export default SideMenuContainer;
