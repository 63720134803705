import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useAppSelector } from '@app/hooks/useAppSelector';
import UseLocalStorage from '@app/hooks/useLocalStorage';
import { selectAppState } from '@app/appSlice';
import MenuConfigType from '@app/types/MenuConfigType';
import constants from '@app/utils/Constants';
import { Button, Layout, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.less';
import { useLazyGetFirmLogoAndNameQuery } from "@app/api/firmApiSlice";

const { Sider } = Layout;

const SideMenu = ({ menu }: { menu: MenuConfigType[] }) => {

  const [theme, setTheme] = useState<any>();

  const { publishedFileCount } = useAppSelector(selectAppState);
  const [collapse, setCollapse] = UseLocalStorage(constants.sideMenuCollapse, false);
  const [hamburgerButtonClicked, setHamburgerButtonClicked] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  const [selectedRoutes, setSelectedRoutes] = useState(getMatchedPaths(menu, location.pathname));
  const [triggerGetFirmLogoAndName] = useLazyGetFirmLogoAndNameQuery();

  useEffect(()=>{
    triggerGetFirmLogoAndName().then((res)=>{
      res?.data && setTheme(res.data)
    })
  },[])

  useEffect(() => {
    // const listener = history.listen(() => {
    //   logger.debug('YJ Client Portal', 'SideMenu', `route change identified : ${location.pathname}`);
    setSelectedRoutes(getMatchedPaths(menu, location.pathname));
    // });
    return () => {
      // listener();
    };
  }, [menu, location]);

  const _toggle = () => {
    collapse ? setCollapse(false) : setCollapse(true);
    setHamburgerButtonClicked(!hamburgerButtonClicked);
  };

  const _redirect = (path: string) => {
    navigate(path);
  };

  const renderSubMenu = (item: MenuConfigType) => {
    return (
      item.children &&
      item.privileged && (
        <SubMenu
          className={styles.yjSubMenuItem}
          disabled={item.noRoute}
          icon={typeof item.icon === 'string' ? <div className={item.icon} /> : <item.icon />}
          key={_makeRouteHash(item.path)}
          title={item.title}
        >
          {_menuItems(item.children)}
        </SubMenu>
      )
    );
  };

  const getMenuItemStyle = (key: string) => {
    return location.pathname === key ? { backgroundColor: `#${theme?.primaryColor}`, transition: 'color 0.3s, background-color 0.3s'} : { transition: 'color 0.3s, background-color 0.3s'};
  };

  const handleMouseEnter = (e:any) => {
    const { domEvent } = e;
    const target = domEvent.currentTarget;
    target.style.background = `#${theme?.primaryColor}`;
  };

  const handleMouseLeave = (e:any, key: string) => {
    const { domEvent } = e;
    const target = domEvent.currentTarget;
    target.style.background  = location.pathname === key ? `#${theme?.primaryColor}` : `#${theme?.secondaryColor}`;
  };

  const renderMenuItem = (item: MenuConfigType) => {
    if (item.noRoute)
      return (
        <Menu.Item
            data-path={item.path}
            style={getMenuItemStyle(item.path)}
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e, item.path)}
          className={styles.yjMenuItemDisabled}
          icon={typeof item.icon === 'string' ? <div className={item.icon} /> : <item.icon />}
          key={_makeRouteHash(item.path)}
          id={_makeRouteHash(item.path)}
        >
          {collapse ? 'This module is coming soon.' : item.title}
        </Menu.Item>
      );
    return (
      item.privileged && (
        <Menu.Item
            data-path={item.path}
            style={getMenuItemStyle(item.path)}
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e, item.path)}
          className={styles.yjMenuItem}
          onClick={() => _redirect(item.path)}
          icon={
            item.hasCount && publishedFileCount && collapse ? (
              <div>
                <item.icon />
                <div className={styles.yj_cp_menu_item_count_collapsed}>{publishedFileCount}</div>
              </div>
            ) : (
              <item.icon />
            )
          }
          key={_makeRouteHash(item.path)}
        >
          {item.hasCount && publishedFileCount && !collapse ? (
            <div className="ant-menu-title-content">
              {item.title}
              <div className={styles.yj_cp_menu_item_count}>{publishedFileCount}</div>
            </div>
          ) : (
            item.title
          )}
        </Menu.Item>
      )
    );
  };

  const _menuItems = (data: MenuConfigType[]) => {
    return data.map((item) => (item.children && item.children.length > 0 ? renderSubMenu(item) : renderMenuItem(item)));
  };

  return (
    <Sider trigger={null} collapsible collapsed={collapse} breakpoint="lg" collapsedWidth="70" className={'yj_cp_sidenav'} width="300">
      <div className={styles.yj_cp_sidemenu_wrapper} style={{backgroundColor: `#${theme?.secondaryColor}`}}  >
        <div style={{ height: 50 }}>
          <Button onClick={_toggle} style={{ position: 'absolute', right: 0 , backgroundColor: `#${theme?.tertiaryColor}` }} className={styles.yj_cp_sidemenu_hamburger_button}>
            {collapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
        <Menu mode="inline" className={styles.yj_cp_sidemenu} defaultSelectedKeys={selectedRoutes}>
          {_menuItems(menu)}
        </Menu>
      </div>
    </Sider>
  );
};

const getMatchedPaths = (routes: MenuConfigType[], location: string) => {
  const matchedPathsArray: string[] = [];
  const generateFlatPathArray = (routeValues: MenuConfigType[], locationValue: string) => {
    if (routeValues) {
      for (const route of routeValues) {
        const matchedRoute = matchPath(
          {
            path: route.path,
            end: route.exact,
          },
          locationValue
        );
        if (matchedRoute) {
          matchedPathsArray.push(_makeRouteHash(route.path));
        }
        if (matchedRoute && route.children) {
          generateFlatPathArray(route.children, locationValue);
        }
      }
    }
  };

  generateFlatPathArray(routes, location);
  return matchedPathsArray;
};

const _makeRouteHash = (key: string) => {
  return key.split('/').join('');
};

export default SideMenu;
