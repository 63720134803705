import AppInitialization from '@app/components/AppInitialization';
import AppRoutes from '@app/routes';
import RouteSwitch from '@app/routes/RouteSwitch';
import constants from '@app/utils/Constants';
import OktaAuthClient, { injectAuthUrl } from '@app/utils/oktaAuthClient/OktaAuthClient';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DiscoveryClient } from '../index';
import '../styles/global.less';
import logger from './utils/logger';

const App: React.FC = () => {
  let navigate = useNavigate();

  const [authURL, setAuthURL] = useState<string>();

  useEffect(() => {

    DiscoveryClient.getOAuthURL().then((url) => {
      injectAuthUrl(url);
      setAuthURL(url);
    });
  }, []);

  logger.info('App', 'REACT_APP_VERSION', process.env.REACT_APP_VERSION);

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || constants.postSigninRedirect, window.location.origin));
  };
  if (authURL)
    return (
      <Security oktaAuth={OktaAuthClient()} restoreOriginalUri={restoreOriginalUri}>
        <RouteSwitch routes={AppRoutes} />
        <AppInitialization />
      </Security>
    );
  else return <Skeleton />;
};

export default App;
