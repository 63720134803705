import { useOktaAuth } from '@okta/okta-react';
import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { AppErrorProps } from './types';

const AppError = (props: AppErrorProps) => {
  const { message, showContactAdmin, autoLogoutTime } = props;
  const { oktaAuth } = useOktaAuth();
  const [timer, setTimer] = useState(autoLogoutTime ?? 0);

  const logout = () => {
    oktaAuth.signOut();
    localStorage.clear();
  };
  const changeTimer = () => {
    setTimer((timer) => (timer > 0 ? timer - 1 : 0));
  };
  let interval: NodeJS.Timeout | undefined;

  useEffect(() => {
    if (autoLogoutTime) {
      setTimeout(logout, autoLogoutTime * 1000);
      interval = setInterval(changeTimer, 1000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <Row justify="center" align="middle" style={{ minHeight: 'calc(100vh - 65px)' }}>
      <Col className="yj_cp_error_banner">
        <Row gutter={20} justify="center" align="middle">
          <Col span="24" style={{ textAlign: 'center', marginBottom: '10px' }}>
            {'Error : ' + message}
          </Col>
          {showContactAdmin && (
            <Col span="24" style={{ textAlign: 'center', marginBottom: '10px' }}>
              Please contact your system administrator.
            </Col>
          )}
          {autoLogoutTime && (
            <Col span="24" style={{ textAlign: 'center', marginBottom: '10px' }}>
              You will be logged out in {timer} seconds.
            </Col>
          )}
          <Button type="primary" onClick={logout} className="yj_cp_error_btns">
            Logout
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default AppError;
